import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import packageJson from '../../../../package.json';
import moment from 'moment';
import validator from 'validator';
import { AbstractControl } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    constructor(private ToastController: ToastController) {}

    public createToast = async (message: string) => {
        const toast = await this.ToastController.create({
            message: message,
            duration: 3000,
        });

        await toast.present();
    };

    public validateURL = (control: AbstractControl): { [key: string]: any } | null => {
        const url = control.value;

        if (url == '' || validator.isURL(url, { protocols: [ 'http', 'https' ], require_protocol: true })) {
            return null;
        }

        return { 'invalidUrl': true }
    }

    public noPeriodsValidator = (control: AbstractControl): { [key: string]: any } | null => {
        const inputText = control.value;

        if (inputText && inputText.includes('.')) return { 'noPeriods': true };

        return null;
    }

    public getFormInputError = (input: any) => {
        if (input && input.invalid) {
            if (input.errors?.['required']) {
                return 'This is a required field';
            }

            if (input.errors?.['email']) {
                return 'Please enter a valid email address';
            }

            if (input.errors?.['minlength']) {
                return `Please enter at least ${input.errors.minlength.requiredLength} characters`;
            }

            if (input.errors?.['min']) {
                return `Please enter an amount greater than or equal to ${input.errors.min.min}`;
            }

            if (input.errors?.['invalidUrl']) {
                return `Please enter a valid URL`;
            }

            if (input.errors?.['noPeriods']) {
                return `Periods are not allowed`;
            }
        }

        return '';
    };

    public getVersion() {
        return packageJson.version;
    }

    public getEnvironment() {
        return environment.env;
    }
    
    public computeDateAfterMonths = (startDate: Date, months: number): Date => {
        const futureDate = moment(startDate).add(months, 'months').toDate();
        return futureDate;
    };
    
    public computeYears = (startDate: Date, endDate: Date): number => {
        const newStartDate = moment(startDate);
        const newEndDate = moment(endDate);
    
        const startYear = newStartDate.year();
        const endYear = newEndDate.year();
        
        let yearsDifference = endYear - startYear;
    
        return yearsDifference;
    };

    public computeTenure = (startDate: any) => {
        if (!startDate) {
            return;
        }

        const currentDate = moment().startOf('day');
        const convertedStartDate = moment(startDate).startOf('day');
        const tenure = moment.duration(currentDate.diff(convertedStartDate));

        return `${tenure.years()}y ${tenure.months()}m ${tenure.days()}d`;
    };
}
